@import url(https://fonts.googleapis.com/css?family=Alata|Berkshire+Swash|Libre+Caslon+Text|Merriweather|Amiko&display=swap);
/* @import url('https://fonts.googleapis.com/css?family=Berkshire+Swash|Carter+One|Concert+One|Damion|Niconne|Oleo+Script+Swash+Caps|Passion+One|Rochester|Rye|Spirax&display=swap'); */
body {
  margin: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  font-family: 'Concert One', cursive;
  font-family: 'Passion One', cursive;
  font-family: 'Carter One', cursive; */
  font-family: 'Alata', sans-serif;
  font-family: 'Merriweather', sans-serif;
  font-family: 'Amiko', sans-serif;
  overflow-y:scroll;
  /* font-family: 'Libre Caslon Text', sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

* {
  box-sizing: border-box;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    -webkit-animation: App-logo-spin infinite 20s linear;
            animation: App-logo-spin infinite 20s linear;
  }
  .Tab::after {
    -webkit-transition: width 0.2s linear;
    transition: width 0.2s linear;
  }
}
.Header {
  position:relative;
  background:#000 url('/header_xxcompressed.jpg');
  background-size:cover;
  background-position: center;
  background-position-y: 40%;
  background-repeat: no-repeat;
  height:40vh;
}
.HeaderTitle {
  font-family:'Berkshire Swash', cursive;
  background-color:#0007;
  padding-top:8px;
  padding-bottom:8px;
  width:100%;
  color:white;
  font-size:9vmin;

  height:100%;
  display:flex;
  flex-direction:column;
  justify-content: center;
}
.HeaderTitle span {
  font-size: 5vmin;
  display:none;
}

.Tab {
  display:inline-block;
  padding:10px;
  padding-bottom:8px;
  width:140px;
  position: relative;
  -webkit-transition: opacity 0.2s linear;
  transition: opacity 0.2s linear;
  opacity:0.6;
  line-height:1.1;
}
.TabMobile {
  display: none;
}
.TabSelected {
  opacity: 1;
}
.TabInner {
  display:inline-flex;
  flex-direction: column;
  align-items: center;
}
.TabInner::after {
  width:0;
  height:2px;
  background-color:white;
  display:block;
  content: '';
  -webkit-transition: width 0.2s linear;
  transition: width 0.2s linear;
  /* position:absolute;
  top:0; */
}
.Tab:hover .TabInner::after {
  width:calc(100% + 10px);
  /* background-color:grey; */
}
.TabSelected .TabInner::after {
  width:calc(100% + 10px);
  background-color:white!important;
  -webkit-transition: width 0.2s linear, background-color 0.2s linear;
  transition: width 0.2s linear, background-color 0.2s linear;
}
.MobileBar {
  display:none;
}

@media only screen and (max-width: 720px) {
  .HeaderTitle {
    font-size:14vmin;
    height:100%;
    display:flex;
    flex-direction:column;
    justify-content: center;
  }
  .HeaderTitle span {
    display:block;
    font-size:8vmin;
  }
  .TabMobile {
    display: block;
    width:100%;
    font-size:24px;
    padding:8px;
    opacity:1;
    text-align: left;
    padding-left:2.5vmin;
  }
  .TabMobile .TabInner::after {
    display:none;
  }
  .Tab:not(.TabMobile) {
    display:none;
  }
  .MobileBar .Tab {
    display:block;
    width:100%;
    font-size:20px;
    padding:8px;
    padding-left:2.5vmin;
    color:white;
    text-align: left;
  }
  .MobileBar .Tab .TabInner::after {
    display:none;
  }
  .Header {
    height:40vh;
  }
  .MobileBar {
    position: relative;
    z-index:10;
    display:block;
    background-color:black;
    height:0;
    overflow:hidden;
    -webkit-transition: height 0.2s linear;
    transition: height 0.2s linear;
  }
  .MobileBar.Open {
    height:41.72262773722627vmin;
    height:190px;
  }
  .MenuNotOpen {
    background-color: transparent!important;
  }
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

.MoreInfo {
  background-color:white;
  border-radius:4px;
  border:1px solid black;
  padding:4px;
  font-family: 'Amiko', sans-serif;
  font-size:1.1em;
  margin-top:4px;
  cursor: pointer;
}

.Page {
  display:inline-flex;
  width:100%;
  flex-direction:row;
  flex-wrap:wrap;
}

.Main {
  width:100%;
}

.Side {
  width:100%;
}

@media only screen and (min-width: 1450px) {
  .Page {
    width:80%;
  }
  .Page.About {
    width:60%;
  }
}

@media only screen and (min-width: 800px) {
  .Main {
    width:60%;
  }
  .Side {
    width:40%;
  }
}

@media only screen and (min-width: 1010px) {
  .Main {
    width:70%;
  }
  .Side {
    width:30%;
  }
}

.Input {
  background-color:white;
  border-radius:4px;
  border:1px solid black;
  padding:4px;
  font-family: 'Amiko', sans-serif;
  margin-top:4px;
}
