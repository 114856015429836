/* @import url('https://fonts.googleapis.com/css?family=Berkshire+Swash|Carter+One|Concert+One|Damion|Niconne|Oleo+Script+Swash+Caps|Passion+One|Rochester|Rye|Spirax&display=swap'); */
@import url('https://fonts.googleapis.com/css?family=Alata|Berkshire+Swash|Libre+Caslon+Text|Merriweather|Amiko&display=swap');
body {
  margin: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  font-family: 'Concert One', cursive;
  font-family: 'Passion One', cursive;
  font-family: 'Carter One', cursive; */
  font-family: 'Alata', sans-serif;
  font-family: 'Merriweather', sans-serif;
  font-family: 'Amiko', sans-serif;
  overflow-y:scroll;
  /* font-family: 'Libre Caslon Text', sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

* {
  box-sizing: border-box;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
